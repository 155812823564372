import { styled } from "linaria/react";

export const RadioChannelNameContainer = styled.div<{ darkMode: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.darkMode ? "#47545A" : "#CED5D8")};
  height: 50px;
  width: 100%;
  font-family: Montserrat, sans-serif;
  color: ${(props) => (props.darkMode ? "#E7E8E8" : "#506873")};
`;

export const ChannelHighlightSquare = styled.div`
  width: 12px;
  height: 12px;
  background-color: #ff3b30; /* Red color */
  margin: 0 16px;
`;

export const RadioChannelName = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 16px;
`;
