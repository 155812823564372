import { styled } from "linaria/react";

export const ChannelContainer = styled.div`
  flex: 1;
  overflow: scroll;
`;

export const ChannelsWrap = styled.div`
  display: flex;
  max-height: calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  width: 100%;
`;

export const AppContainer = styled.div<{ darkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.darkMode ? "#121212" : "#f5f5f5")};
  height: 100vh;
  transition: background 0.3s;
  width: 100%;
`;
