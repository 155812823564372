import { styled } from "linaria/react";

// Styled container with top and bottom borders
export const DispatchContainer = styled.div<{ darkMode: boolean }>`
  width: 100%;
  max-width: calc(100% - 30px);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-top-color: ${(props) => (props.darkMode ? "#3E4245" : "#E6EAEB")};
  border-bottom-color: ${(props) => (props.darkMode ? "#3E4245" : "#E6EAEB")};
  padding: 10px 15px;
  font-family: Montserrat, sans-serif;
  animation: colorize 1s linear;

  @keyframes colorize {
    0% {
      background-color: #cee6c1;
    100% {
      background-color:${(props) => (props.darkMode ? "#282B36" : "#ffffff")};
    }
  }
`;

// Top row: "DISPATCH" label + time on the right
export const TopRow = styled.div<{ darkMode: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

// Light gray "DISPATCH" text
export const Label = styled.div<{ darkMode: boolean }>`
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => (props.darkMode ? "#8D8F93" : "#96A3AA")};
  font-weight: bold;
`;

// Right side: Icon + Time
export const TimeWrapper = styled.div<{ darkMode: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: ${(props) => (props.darkMode ? "#8D8F93" : "#96A3AA")};
`;

// Main message in bold
export const Message = styled.div<{ darkMode: boolean }>`
  font-size: 16px;
  color: ${(props) => (props.darkMode ? "#C4C4C4" : "#506873")};
`;

// Replay button
export const ReplayButton = styled.button<{ darkMode: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${(props) => (props.darkMode ? "#B2B9C0" : "#59717B")};

  svg {
    width: 20px;
    height: auto;
    stroke-width: 1; /* Ensures the stroke remains visible */
  }
`;
