import { styled } from "linaria/react";

export const MasterContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  background-color: #a7b4b9;
  color: #000;
  width: 100%;
  font-family: Montserrat, sans-serif;
`;

// Container for the row
export const Row = styled.div<{ darkMode: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: ${(props) => (props.darkMode ? "#192D38" : "#f0f0f0")};
  color: ${(props) => (props.darkMode ? "#BEBEBE" : "#282C2E")};
  gap: 10px;
`;

// Logo styles
export const LogoColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 32px;
  margin-left: 8px;
`;

export const LogoWrapper = styled.div<{ darkMode: boolean }>`
  color: ${(props) => (props.darkMode ? "#BEBEBE" : "#282C2E")}; /* White in dark mode, dark gray in light mode */

  svg {
    width: 100%; /* Ensures the SVG scales properly */
    height: auto;
  }
`;

// Column styles
export const IconColumn = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 16px;
`;

export const TextColumn = styled.div`
  flex: 1; // Takes up remaining space (up to 60%)
  max-width: 80%;
  font-size: 18px;
`;

export const ButtonColumn = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const StrokedIconButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  font-size: 16px;
  color: #333;
  border: 2px solid gray;
  background: transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: black;
    color: black;
  }
`;

export const Spacer = styled.div`
  height: 20px; /* Adjust the height as needed */
`;

// Close Tray button
export const CloseTrayButton = styled.button<{ darkMode: boolean }>`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${(props) => (props.darkMode ? "#B2B9C0" : "#506873")}; /* White in dark mode, dark gray in light mode */

  svg {
    width: 20px;
    height: auto;
    stroke-width: 1; /* Ensures the stroke remains visible */
  }
`;
